/** @jsx jsx */
import { css, jsx } from "@emotion/react"
import React, { useContext, useState, useEffect, useRef } from "react"
import Button from "../Elements/Button"
import CartContext from "../../context/Cart/CartContext"
import Modal from "../Modal/Modal"
import RadioGroupCart from "../Cart/RadioGroup"
import Done from "../../assets/icons/done.svg"
import DynamicForm from "../Forms/DynamicForm/DynamicForm"
import { getMySingleForm, getUserAddressBookListing, getUserAddressListing, addMySingleNewForm, getSingleAssetQty } from "../../helpers/DrupalHelper"
import StateContext from "../../context/state/StateContext"
import NotificationContext from "../../context/Notification/NotificationContext"
function ModalCart(props) {

  const { status, user_group, assetData } = props
  const [selectedStores, setSelectedStores] = useState([])
  const [openCreate, setOpenCreate] = useState(false)
  const [formData, setFormData] = useState(null)
  const [suppData, setSuppData] = useState([])
  const [savedStoreId, setSavedStoreId] = useState(null)
  const [clickedOn, setClickedOn] = useState(null)
  const [storeOpen, setStoreOpen] = useState(false)

  const { state } = useContext(StateContext)
  const { toggleNotification, notificationIsVisible } =
    useContext(NotificationContext)

  const ref = useRef(null)

  useEffect(async () => {
    let formDataResponse = await getMySingleForm(state.token, "address", "default")
    const { field_hide_from_cart, ...rest } = formDataResponse

    setFormData({ ...rest })

    let supplementaryData = await getUserAddressBookListing(state.token)
    setSuppData(supplementaryData)
  }, [])

  useEffect(() => {
    if (openCreate === true && ref.current) {

      ref.current.scrollIntoView({ behavior: 'smooth', block: 'start' })

    }
  }, [openCreate, ref])

  const [newAddresses, setNewAddresses] = useState([])
  const [newAssetData, setNewAssetData] = useState({})

  const onSave = async (data, v) => {
    console.log(data, "data")
    if (data.field_address_type === "store" && data.field_store === "")
      return toggleNotification({
        content: "Please select a store.",
        error: true,
      })
    if (v === "added") {

      setOpenCreate(false)

      let supplementaryData = await getUserAddressBookListing(state.token)
      setSuppData(supplementaryData)

      setSuppData(supplementaryData)
      toggleNotification({ content: "Address Added Sucessfully" })

      const newAssetDataData = await getSingleAssetQty(state.token, props.id)
      setNewAssetData(newAssetDataData)

      const datas = await getUserAddressListing(state.token)
      setNewAddresses(datas)
      // setNewAddresses((prev) => [...prev, data])
      console.log(newAssetDataData, "newAssetDataData")

    }
  }

  const onStoreSave = async (data, v) => {
    // alert(v)
    // console.log(data)
    if (v === "added") {
      let res = await addMySingleNewForm(
        state.token,
        "store",
        "retail_store",
        data
      )
      if (res.status === 200) {
        toggleNotification({
          content: (
            <p className="text-sm font-medium text-gray-900">Store Added</p>
          ),
        })
        const x = await res.json()
        if (x.id?.[0]?.value) setSavedStoreId(x.id?.[0]?.value)
      } else
        toggleNotification({
          content: (
            <p className="text-sm font-medium text-gray-900">
              fail to add Store
            </p>
          ),
        })
      // let newList = [...list, data]
      // setList(await getMyListing(state.token, "store", "retail_store"))

      //if clickedOn is create, then open create modal again  and if
      //clickedOn is edit, then open edit modal again
      if (clickedOn === "create") {
        setStoreOpen(false)
        setOpenCreate(true)
      }
      // else if (clickedOn === "edit") {
      //   setStoreOpen(false)
      //   setOpen(true)
      // }
    } else {
      toggleNotification({
        content: (
          <p className="text-sm font-medium text-red-600">{data}Failed </p>
        ),
      })
    }
  }

  const {
    updateCartQuantities,
    updateRequest,
    userAddresses,
    cart,
    orderDigitalAsset,
  } = useContext(CartContext)
  const filteredUserAddresses = userAddresses.filter(
    item => item.hidden_from_cart !== true
  )
  const temp_cart = JSON.parse(localStorage.getItem("temp_cart"))?.filter(
    item => item.asset_id === props.id
  )
  const totalMaxQtyOrdered = props.stores?.reduce(
    (acc, store) => acc + store.max_qty_ordered,
    0
  )

  const tempTotalMaxQtyOrdered =
    Array.isArray(temp_cart) &&
    temp_cart[0]?.status.stores.reduce(
      (acc, store) => acc + store.max_qty_ordered,
      0
    )

  const [error, setError] = useState(null)

  const [loadingApi, setLoadingApi] = useState(false)

  useEffect(() => {
    if (filteredUserAddresses.length > 0) {

      setSelectedStores(
        cart.filter(f => parseInt(f.asset_id) === parseInt(props.id)).length > 0
          ? cart.filter(f => parseInt(f.asset_id) === parseInt(props.id))[0]
            .addresses
          : [
            {
              address_id: filteredUserAddresses[0]?.id,
              quantity: props.count ? props.count : 1,
            },
          ]
      )
    }
  }, [userAddresses, props.count])
  if (!assetData) return null
  return (
    <>
      <Modal
        title={
          <div className="flex items-center justify-center">
            <Done />
            <h2 className="ml-4 text-strong-25">Address Selection</h2>
          </div>
        }
        isOpen={props.open}
        closeModal={() => props.closeModal(false)}
      >
        <div className="relative">
          <div
            style={{
            }}
          >
            {filteredUserAddresses.length === 0 ? (
              "Loading"
            ) : (
              <>
                <p className="text-reg-16">
                  {assetData.type === "digital"
                    ? "Your file is ready for download, but first let us know which locations plan to use this file."
                    : status === "must_request"
                      ? "Please let us know which addresses you would like to request this asset for."
                      : "We're nearly ready to process your order, but first let us know which location you want us to ship these items to."}
                </p>
                {error !== null && <p className="text-red">{error}</p>}
                <div>
                  <RadioGroupCart
                    {...props}
                    withOrderStores={
                      temp_cart
                        ? totalMaxQtyOrdered >= tempTotalMaxQtyOrdered
                          ? props.stores
                          : temp_cart[0]?.status.stores
                        : props.stores
                    }
                    assetId={props.id}
                    addresses={userAddresses.filter(
                      item => item.hidden_from_cart !== true
                    )}
                    newAddresses={
                      newAddresses
                    }
                    value={selectedStores}
                    onChange={v => {

                      if (v !== selectedStores) setSelectedStores(v)
                    }}
                    assetData={{ ...assetData, ...newAssetData }}
                  />
                </div>

                <div className="w-[100%] mb-[5px] mx-auto flex">
                  <Button
                    red
                    // full
                    widthAuto
                    loading={loadingApi}
                    onClick={async () => {
                      setLoadingApi(true)

                      if (selectedStores.length === 0) {
                        setLoadingApi(false)
                        return setError("You must select at least one store.")
                      }

                      if (assetData.type === "digital") {
                        await orderDigitalAsset({
                          assetId: props.id,
                          addresses: selectedStores,
                          title: assetData?.title || null,
                        })
                        setLoadingApi(false)
                        props.closeModal(false)
                        return
                      }

                      if (status === "must_request") {
                        props.closeModal(false)
                        await updateRequest(props.id, selectedStores)
                        setLoadingApi(false)
                      } else {
                        await updateCartQuantities({
                          assetId: props.id,
                          addresses: selectedStores,
                          userGroup: user_group,
                          title: assetData?.title || null,
                        })
                        setLoadingApi(false)
                      }
                    }}
                  >
                    {assetData.type === "digital"
                      ? "Download file"
                      : status === "must_request"
                        ? "Request"
                        : "Add to cart"}
                  </Button>
                  <div className="ml-auto">
                    <Button blue widthAuto
                      onClick={() => {

                        // props.closeModal(false)
                        if (openCreate === true) setOpenCreate(false)
                        else setOpenCreate(true)

                      }}
                    >{openCreate ? `Cancel` : `Add new Address`}</Button>
                  </div>
                </div>
              </>
            )}
          </div>
          {openCreate === true &&
            <div
              // className={openCreate ? "absolute top-0 bg-[rgba(255,255,255,.7)] backdrop-blur-md p-10" : ""}
              ref={ref}
            >
              <h3
                className="text-strong-20 !text-left mt-5"
              >Create a New Address</h3>
              <DynamicForm
                onSave={onSave}
                addNew={true}
                data={
                  formData
                }
                token={state.token}
                name="address"
                bundle="default"
                dynamicFields={[
                  {
                    subject: "field_store",
                    controller: "field_address_type",
                    if_equals: ["store"],
                  },
                  {
                    subject: "field_location",
                    controller: "field_address_type",
                    if_equals: ["other", "locker", "home", "office"],
                  },
                  {
                    subject: "field_first_name",
                    controller: "field_address_type",
                    if_equals: ["other", "locker", "home", "office", "store"],
                  },
                  {
                    subject: "field_last_name",
                    controller: "field_address_type",
                    if_equals: ["other", "locker", "home", "office", "store"],
                  },
                  {
                    subject: "field_company_name",
                    controller: "field_address_type",
                    if_equals: ["other", "locker", "home", "office"],
                  },
                ]}
                suppData={suppData}
                onExtraClick={() => {

                  if (!notificationIsVisible) setOpenCreate(false)
                  setStoreOpen(true)
                  setClickedOn("create")

                }}
                savedStoreId={savedStoreId}
              />

            </div>}
        </div>
      </Modal>
    </>
  )
}

export default ModalCart
